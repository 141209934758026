// extracted by mini-css-extract-plugin
export var lbContainerOuter = "B_tL";
export var lbContainerInner = "B_tM";
export var movingForwards = "B_tN";
export var movingForwardsOther = "B_tP";
export var movingBackwards = "B_tQ";
export var movingBackwardsOther = "B_tR";
export var lbImage = "B_tS";
export var lbOtherImage = "B_tT";
export var prevButton = "B_tV";
export var nextButton = "B_tW";
export var closing = "B_tX";
export var appear = "B_tY";