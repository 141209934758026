import React from 'react';
import HTMLParser from 'html-react-parser';

import { formColor } from '../../../../../helper';

import * as styles from './styles.module.css';

class FAQLayout extends React.Component {
  constructor(props) {
    super(props);

    const index = props.section.data.length - 1;
    const align = props.section.data[index].align !== undefined ? props.section.data[index].align : 'Left';

    this.state = {
      align,
      openAccordion: null,
    };
  }

  inViewport = (el) => {
    const rect = el.getBoundingClientRect();

    return (
      rect?.top >= 0 &&
      rect?.left >= 0 &&
      rect?.bottom <= (window.innerHeight || document.documentElement.clientHeight) &&
      rect?.right <= (window.innerWidth || document.documentElement.clientWidth)
    );
  };

  handleArrowClick = (event) => {
    const elem = event.target;
    const openAccordion = Number(elem.dataset.index) === this.state.openAccordion ? null : Number(elem.dataset.index);
    this.setState({ openAccordion });
    setTimeout(() => {
      if (!this.inViewport(elem)) elem?.scrollIntoView();
    }, 10);
  };

  createFAQS = () => {
    const activeComponents = [];
    this.props.section.data.map((item, index) => {
      if (item.type === 'COMPONENT' && item.active) activeComponents.push({ item, index });
      return null;
    });

    let componentsWrapper = styles.FAQComponentsWrapper;
    if (this.props.layout === 'clean') {
      componentsWrapper = styles.FAQComponentsWrapperClean;
    }

    const faqs = [];
    activeComponents.forEach((item, index) => {
      let style;
      if (item.item.styles.backgroundColor.active) {
        let { backgroundColor } = item.item.styles;
        if (item.item.styles.backgroundColor.solid === '' && item.item.styles.backgroundColor.gradient.from === '') {
          backgroundColor = { solid: '#ffffff' };
        }

        style = formColor(
          backgroundColor,
          false,
          item.item.styles.backgroundColor.opacity,
          undefined,
          this.props.themeData.colors,
        );
      }

      let tempArrow = 'accordion_arrow';
      let itemStyle;

      if (this.props.layout === 'clean') {
        itemStyle = 'accordion__item';
        if ((this.state.openAccordion !== null && index === this.state.openAccordion) || !item.item.data[0].active) {
          tempArrow = 'accordion_arrow_open';
        }
      } else if (
        (this.state.openAccordion !== null && index === this.state.openAccordion) ||
        !item.item.data[0].active
      ) {
        tempArrow = 'accordion_arrow_2_open';
      } else {
        tempArrow = 'accordion_arrow_2';
      }

      const hidden =
        this.state.openAccordion === null || index !== this.state.openAccordion || !item.item.data[0].active;

      const textStyle = !hidden ? {} : { display: 'none' };
      const text = item.item.data[1].active ? (
        <div
          className={`${styles.FAQComponentParagraph} ${styles[`align${item.item.align}`]}`}
          style={textStyle}
          data-index={index}
        >
          {HTMLParser(`<span>${item.item.data[1].text}</span>`)}
        </div>
      ) : undefined;

      const faq = (
        <div
          key={`FAQLayout_Section_${this.props.section._id}_${index}`}
          style={style}
          className={componentsWrapper}
          data-index={index}
          onClick={this.handleArrowClick}
        >
          {this.props.layout === 'clean' && index > 0 && <hr className={styles.FAQDivider} />}
          <div data-index={index}>
            <div data-index={index}>
              <div data-index={index} className={styles[itemStyle]}>
                {item.item.data[0].active ? (
                  <div data-index={index}>
                    <div className={styles[tempArrow]} data-index={index} />
                    <div className={`${styles[`align${item.item.align}`]}`} data-index={index}>
                      <div className={styles.FAQComponentHeader} data-index={index}>
                        <h5 data-index={index}>{HTMLParser(`${item.item.data[0].text}`)}</h5>
                      </div>
                    </div>
                  </div>
                ) : (
                  <div data-index={index} className={styles.positioner} />
                )}
                {text}
              </div>
            </div>
          </div>
        </div>
      );

      faqs.push(faq);
    });

    return faqs;
  };

  render() {
    const alignStyle = `align${this.state.align}`;
    const faqs = this.createFAQS();

    return (
      <div className="container">
        <div className="row">
          <div className={`col-12 col-sm-10 offset-sm-1 col-xl-8 offset-xl-2 ${styles[alignStyle]}`}>
            {this.props.section.data.map((elem, i) => {
              let result;
              if (elem.active && elem.type === 'HEADINGS/HEADING-TWO') {
                result = (
                  <div key={`${this.props.section._id}_data_${i}`} className={styles.FAQMainHeader}>
                    {HTMLParser(`<h2>${elem.text}</h2>`)}
                  </div>
                );
              } else if (elem.active && elem.type === 'PARAGRAPH/PARAGRAPH') {
                result = (
                  <div key={`${this.props.section._id}_data_${i}`}>{HTMLParser(`<span>${elem.text}</span>`)}</div>
                );
              }

              return result;
            })}
            <div className={styles.accordionWrapper}>{faqs}</div>
          </div>
        </div>
      </div>
    );
  }
}

export default FAQLayout;
