// extracted by mini-css-extract-plugin
export var alignLeft = "t_rV d_fn d_bF d_dt";
export var alignCenter = "t_bN d_fp d_bC d_dv";
export var alignRight = "t_rW d_fq d_bG d_dw";
export var FAQMainHeader = "t_kv d_kv d_v d_bx";
export var FAQComponentHeader = "t_kB d_kB d_bw";
export var FAQComponentParagraph = "t_kw d_kw d_cC";
export var FAQComponentsWrapper = "t_ks d_ks d_cm d_cK d_cQ d_c1 d_cW d_dz";
export var FAQComponentsWrapperClean = "t_kt d_kt d_cm d_cK d_cQ d_cZ d_dz";
export var accordion__body = "t_kC d_kC d_bx";
export var accordion__bodyHidden = "t_kF d_kF d_bv d_dk";
export var FAQDivider = "t_kr d_kr";
export var accordionWrapper = "t_sc d_cf";
export var accordion__item = "t_sd d_cK";
export var accordion_arrow = "t_kJ d_kJ";
export var accordion_arrow_open = "t_kL d_kL";
export var accordion_arrow_2 = "t_kN d_kN";
export var accordion_arrow_2_open = "t_kQ d_kQ";