// extracted by mini-css-extract-plugin
export var customText = "m_p5 d_dt d_cr d_cf";
export var videoIframeStyle = "m_pk d_d4 d_v d_G d_bx d_b0 d_Q";
export var customImageWrapper = "m_p6 d_cr d_cf d_Y";
export var customRow = "m_pF d_bC d_bd";
export var quoteWrapper = "m_gQ d_bB d_bN d_cr d_cf";
export var quoteBar = "m_pp d_G";
export var masonryImageWrapper = "m_pw";
export var title = "m_pS";
export var Title3Small = "m_p7 q_p7 q_qJ q_qR";
export var Title3Normal = "m_p8 q_p8 q_qJ q_qS";
export var Title3Large = "m_p9 q_p9 q_qJ q_qT";