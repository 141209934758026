// extracted by mini-css-extract-plugin
export var navbarDividedLeft = "y_fQ d_fQ d_by d_bG d_bH";
export var navbarDividedRight = "y_fR d_fR d_by d_bH";
export var menuDesign6 = "y_sy d_fM d_by d_dv d_bN d_bH";
export var menuDesign7 = "y_sz d_fM d_by d_dv d_bN d_bH";
export var menuRight = "y_sB d_fM d_by d_dv d_bN d_bH";
export var menuLeft = "y_sC d_fM d_by d_dv d_bN d_bH";
export var menuCenter = "y_sD d_fN d_fM d_by d_dv d_bN d_v d_bC d_bH";
export var menuDivided = "y_qh d_fN d_fM d_by d_dv d_bN d_v d_bC";
export var menuDesign5 = "y_qs d_fP d_fM d_by d_dv d_bN d_bH";
export var isBurger = "y_sF";
export var navbarItem = "y_qj d_bw";
export var navbarLogoItemWrapper = "y_fY d_fY d_bB d_bN";
export var burgerToggleVisibleOpen = "y_sG d_gd d_bx d_Y d_br";
export var burgerToggleVisible = "y_sH d_gd d_bx d_Y d_br";
export var burgerToggle = "y_sJ d_gd d_bx d_Y d_br d_Y";
export var burgerToggleOpen = "y_sK d_gd d_bx d_Y d_br";
export var burgerInput = "y_sL d_f2 d_v d_G d_bx d_cb d_dk d_bc d_Z d_7 d_6 d_3 d_8";
export var burgerIcon = "y_sM d_f3 d_v d_G";
export var burgerLine = "y_sN d_f1";
export var burgerMenuDesign6 = "y_sP d_f7 d_f6 d_f4 d_f5 d_Z d_cb d_dl d_br d_dv";
export var burgerMenuDesign7 = "y_sQ d_f7 d_f6 d_f4 d_f5 d_Z d_cb d_dl d_br d_dv";
export var burgerMenuLeft = "y_sR d_f7 d_f6 d_f4 d_f5 d_Z d_cb d_dl d_br d_dv d_f7 d_f6 d_f4 d_f5 d_Z d_cb d_dl d_br d_dv";
export var burgerMenuDesign5 = "y_sS d_f8 d_f6 d_f4 d_f5 d_Z d_cb d_dl d_br d_dv";
export var burgerMenuRight = "y_sT d_f8 d_f6 d_f4 d_f5 d_Z d_cb d_dl d_br d_dv";
export var burgerMenuCenter = "y_sV d_f9 d_f6 d_f4 d_f5 d_Z d_cb d_dl d_br d_dv";
export var burgerMenuDivided = "y_sW d_f7 d_f6 d_f4 d_f5 d_Z d_cb d_dl d_br d_dv";
export var secondary = "y_sX d_bB d_bN";
export var staticBurger = "y_sY";
export var menu = "y_sZ";
export var navbarDividedLogo = "y_s0";
export var nav = "y_s1";