// extracted by mini-css-extract-plugin
export var quoteParagraphLeft = "w_sk d_gS d_cv d_dt";
export var quoteParagraphCenter = "w_gT d_gT d_cv d_dv";
export var quoteParagraphRight = "w_gV d_gV d_cv d_dw";
export var quoteRowLeft = "w_sl d_bF";
export var quoteRowCenter = "w_sm d_bC";
export var quoteRowRight = "w_sn d_bG";
export var quoteWrapper = "w_gQ d_gQ d_v d_cC d_by d_bN d_bC";
export var quoteContentWrapper = "w_gR d_gR";
export var quoteExceptionSmall = "w_rB q_rB";
export var quoteExceptionNormal = "w_rC q_rC";
export var quoteExceptionLarge = "w_rD q_rD";
export var quoteAuthorExceptionSmall = "w_rF q_rF";
export var quoteAuthorExceptionNormal = "w_rG q_rG";
export var quoteAuthorExceptionLarge = "w_rH q_rH";