// extracted by mini-css-extract-plugin
export var heroHeaderLeft = "s_r4 d_gv d_cr";
export var heroHeaderCenter = "s_gw d_gw d_cr d_dv";
export var heroHeaderRight = "s_gx d_gx d_cr d_dw";
export var heroParagraphLeft = "s_r5 d_gr d_cv";
export var heroParagraphCenter = "s_gs d_gs d_cv d_dv";
export var heroParagraphRight = "s_gt d_gt d_cv d_dw";
export var heroBtnWrapperLeft = "s_r6 d_d1 d_d0 d_v d_by d_bF";
export var heroBtnWrapperCenter = "s_r7 d_d2 d_d0 d_v d_by d_bC";
export var heroBtnWrapperRight = "s_r8 d_d3 d_d0 d_v d_by d_bG";
export var overlayBtnWrapper = "s_r9 d_gq d_Z d_6 d_7 d_8 d_bm d_cb";
export var design4 = "s_sb d_gp d_Z d_6 d_7 d_bm";
export var heroExceptionSmall = "s_rt q_rt";
export var heroExceptionNormal = "s_rv q_rv";
export var heroExceptionLarge = "s_rw q_rw";
export var Title1Small = "s_rc q_rc q_qJ q_qK";
export var Title1Normal = "s_rd q_rd q_qJ q_qL";
export var Title1Large = "s_rf q_rf q_qJ q_qM";
export var BodySmall = "s_rn q_rn q_qJ q_q1";
export var BodyNormal = "s_rp q_rp q_qJ q_q2";
export var BodyLarge = "s_rq q_rq q_qJ q_q3";